import tokens from '@/_generated/tokens/tokens-nrk.json';
import sardis from '@/assets/images/networks/sardis.svg';
// import contracts from '@/_generated/contracts/contract-abi-etc.json';

export default {
  name: 'NRK',
  name_long: 'Nordek',
  homePage: 'https://nordekscan.com/',
  blockExplorerTX: 'https://nordekscan.com/tx/[[txHash]]',
  blockExplorerAddr: 'https://nordekscan.com/address/[[address]]',
  chainID: 81041,
  tokens: tokens,
  contracts: [],
  icon: sardis,
  currencyName: 'NRK',
  isTestNetwork: false,
  isEthVMSupported: {
    supported: false,
    url: null,
    websocket: null
  },
  gasPriceMultiplier: 1,
  canBuy: false,
  coingeckoID: null
};
