var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mew-overlay',{attrs:{"footer":{
    text: 'Need help?',
    linkTitle: 'Contact support',
    link: 'mailto:support@wallet.nordekscan.com'
  },"show-overlay":_vm.open,"title":"Add custom token","close":_vm.reset,"back":_vm.step === 2 ? _vm.back : null,"content-size":_vm.step === 2 ? 'large' : 'medium'}},[_c('div',{staticClass:"full-width"},[(_vm.step === 1)?_c('div',{staticClass:"full-width"},[_c('div',{staticClass:"mew-heading-3 mb-4 textDark--text"},[_vm._v(" What is the token contract address? ")]),_c('mew-input',{attrs:{"value":_vm.contractAddress,"placeholder":"Contract address"},on:{"input":_vm.setContractAddress}})],1):_vm._e(),(_vm.step === 2)?_c('div',{staticClass:"full-width"},_vm._l((_vm.tokenDataToDisplay),function(tkn,idx){return _c('v-row',{key:idx,class:[
          'd-flex align-center mt-0',
          _vm.tokenDataToDisplay[idx + 1] &&
          !_vm.isIcon(_vm.tokenDataToDisplay[idx + 1].name) &&
          !_vm.tokenDataToDisplay[idx + 1].value
            ? 'mb-1'
            : 'mb-9'
        ],attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"ml-5 textLight--text mew-body font-weight-bold",attrs:{"cols":"2"}},[_vm._v(" "+_vm._s(tkn.name)+" ")]),_c('v-col',{staticClass:"textDark--text ml-2",attrs:{"cols":"8"}},[(
              !_vm.isIcon(tkn.name) && !_vm.isContractAddress(tkn.name) && tkn.value
            )?_c('span',[_vm._v(_vm._s(tkn.value))]):_vm._e(),(_vm.isContractAddress(tkn.name))?_c('mew-transform-hash',{attrs:{"justify-start":"","hash":tkn.value}}):_vm._e(),(_vm.isIcon(tkn.name) && tkn.value)?_c('img',{attrs:{"height":"24px","width":"23.5px","src":tkn.value,"alt":"token icon"}}):_vm._e(),(_vm.isIcon(tkn.name) && !tkn.value)?_c('div',{staticClass:"token-placeholder mew-caption d-flex align-center justify-center"},[_vm._v(" NA ")]):_vm._e(),(!_vm.isIcon(tkn.name) && !tkn.value)?_c('mew-input',{staticClass:"mt-8",attrs:{"id":idx,"error-messages":idx === 3 ? _vm.symbolLengthTooLong : _vm.nameLengthTooLong,"placeholder":_vm.getPlaceholder(tkn.name)},on:{"input":_vm.setInputValue}}):_vm._e()],1)],1)}),1):_vm._e(),_c('mew-button',{attrs:{"btn-size":"xlarge","has-full-width":"","loading":_vm.loading,"disabled":_vm.isDisabled,"title":_vm.step === 1 ? 'Next' : 'Add Token'},nativeOn:{"click":function($event){return _vm.next.apply(null, arguments)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }