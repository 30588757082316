import {
  ledgerEthereum,
  ledgerLiveEthereum,
  ledgerEthereumClassic,
  ledgerLiveEthereumClassic,
  ropsten,
  rskMainnet,
  rskTestnet,
  thundercore,
  solidum,
  goChain
} from '../configs/configPaths';
export default [
  ledgerEthereum,
  ledgerLiveEthereum,
  ledgerEthereumClassic,
  ledgerLiveEthereumClassic,
  ropsten,
  rskMainnet,
  rskTestnet,
  thundercore,
  solidum,
  goChain
];
