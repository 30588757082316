import { tSARDIS } from '../types';
export default {
  type: tSARDIS,
  service: 'bankcex.com-ws',
  url: 'wss://testnet-rpc.nordekscan.com/ws',
  port: 443,
  auth: false,
  username: '',
  password: ''
};
