var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mew-dropdown',{ref:"mewDropdown",attrs:{"popup-title":"Select a derivation path"},scopedSlots:_vm._u([{key:"activatorBtnText",fn:function(){return [_c('span',{staticClass:"textDark--text mew-body capitalize"},[_vm._v(" "+_vm._s(_vm.selectedPath.name)+" ")]),_c('span',{staticClass:"path mew-body capitalize"},[_vm._v(_vm._s(_vm.selectedPath.value))])]},proxy:true},{key:"cardContent",fn:function(){return [_c('div',[_c('mew-search',{staticClass:"mb-8",attrs:{"value":_vm.searchValue,"placeholder":"find a path","is-search-block":""},on:{"input":_vm.setSearch}}),_vm._l((_vm.filteredCustomPaths),function(filteredCustomPath,idx){return _c('div',{key:filteredCustomPath.name + idx,staticClass:"mb-6 d-flex align-center justify-space-between cursor-pointer",on:{"click":function($event){return _vm.setPath(filteredCustomPath)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{staticClass:"mr-2",attrs:{"src":require('@/assets/images/currencies/eth.png'),"contain":"","max-height":"24px","max-width":"24px"}}),_c('span',{class:[
                filteredCustomPath.name === _vm.selectedPath.name
                  ? 'greenPrimary--text'
                  : 'textDark--text'
              ]},[_vm._v(_vm._s(filteredCustomPath.name))])],1),_c('span',{class:filteredCustomPath.value === _vm.selectedPath.value
                ? 'greenPrimary--text'
                : 'path'},[_vm._v(" "+_vm._s(filteredCustomPath.value)+" ")])])}),(_vm.filteredCustomPaths.length > 0 && _vm.filteredPaths.length > 0)?_c('v-divider',{staticClass:"mb-6"}):_vm._e(),_vm._l((_vm.filteredPaths),function(filteredPath,idx){return _c('div',{key:filteredPath.name + idx,staticClass:"mb-7 d-flex align-center justify-space-between cursor-pointer",on:{"click":function($event){return _vm.setPath(filteredPath)}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{staticClass:"mr-2",attrs:{"src":require('@/assets/images/currencies/eth.png'),"contain":"","max-height":"24px","max-width":"24px"}}),_c('span',{class:[
                filteredPath.name === _vm.selectedPath.name
                  ? 'greenPrimary--text'
                  : 'textDark--text'
              ]},[_vm._v(_vm._s(filteredPath.name))])],1),_c('span',{class:filteredPath.value === _vm.selectedPath.value
                ? 'greenPrimary--text'
                : 'path'},[_vm._v(" "+_vm._s(filteredPath.value)+" ")])])}),_c('div',{staticClass:"text-right greenPrimary--text cursor-pointer",on:{"click":function($event){_vm.showCustomField = !_vm.showCustomField}}},[_vm._v(" + Add Path ")]),(_vm.showCustomField)?_c('div',{staticClass:"mt-4"},[_c('mew-input',{attrs:{"label":"Alias","placeholder":"my custom path"},on:{"input":_vm.setCustomAlias}}),_c('mew-input',{attrs:{"label":"Path","placeholder":"m/44’/1’/0’/0"},on:{"input":_vm.setCustomPath}}),_c('v-row',{staticClass:"d-flex align-center",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-1",attrs:{"cols":"6"}},[_c('mew-button',{attrs:{"has-full-width":"","btn-style":"outline","title":"Cancel"},nativeOn:{"click":function($event){_vm.showCustomField = !_vm.showCustomField}}})],1),_c('v-col',{staticClass:"pl-1",attrs:{"cols":"6"}},[_c('mew-button',{attrs:{"has-full-width":"","title":"Add Path"},nativeOn:{"click":function($event){return _vm.saveCustomPath.apply(null, arguments)}}})],1)],1)],1):_vm._e()],2)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }